
!(function () {
  'use strict';
  
  jQuery( window ).load(function () {
    if( jQuery( '#exercise-timer-wrapper' ).length < 1 ){
      return;
    }
    
    gbsSetupAnimationsFrameSizes();
    gbsCheckTweenMaxLoadedAndStartAnimation( );
  });
  
  jQuery( window ).resize(function () {
    if( jQuery( '#exercise-timer-wrapper' ).length < 1 ){
      return;
    }
    gbsSetupAnimationsFrameSizes();
  });
  
  jQuery( document ).ready(function () {
    if( jQuery( '#exercise-timer-wrapper' ).length < 1 ){
      globalTimerCheck( )
      return;
    }
  });
  
}());



function gbsRedirectToExcerciseEnd( basilInstance ) {
  
  //what to do with this? TODO; remove?
  return false;

  setTimeout(function () {
      window.location.href = globalActionAfterExerciseEndUri;
  }, 1000);
  
  basilInstance.remove( 'timer_start_ts' );
  basilInstance.remove( 'timer_started' );
  
}


function globalTimerCheck( ) {
  //what to do with this? TODO; remove?
  return false;

  var basilInstance = new window.Basil( {
    namespace: '',
    storages: ['local', 'cookie'],
    expireDays: 3600
  } );
  
  var preSetTimer = 0;
  var test = basilInstance.get( 'timer_start_ts' );
  
  if( test != null && parseInt( test ) > 0 ){
    var date = new Date();
    var passed = date.getTime() - parseInt( test );
    preSetTimer = parseInt( passed );
  } else {
    // do nothing, no timer
    return;
  }
  
  if( preSetTimer >= 1200000  ){ // 20mins in miliseconds
    gbsRedirectToExcerciseEnd( basilInstance );
  } else {
    var to = (1200000  - preSetTimer );
    setTimeout( function(){
      gbsRedirectToExcerciseEnd( basilInstance );
    }, to );
  }
  
}

function gbsCheckTweenMaxLoadedAndStartAnimation( ) {
  // return false;
  
  if( window.TweenLite && window.TimelineLite && window.CSSPlugin && window.Ease ){
    var exerciseHolderInstance = new GbsExerciseAnimator();
    exerciseHolderInstance.init();
    exerciseHolderInstance.initAnimation();
  } else {
    setTimeout(function(){ gbsCheckTweenMaxLoadedAndStartAnimation(); }, 250);
  }
  
}


function gbsIsiPhone(){
    return (
        (navigator.platform.indexOf("iPhone") != -1) ||
        (navigator.platform.indexOf("iPod") != -1) ||
        (navigator.platform.indexOf("iPad") != -1) ||
        (navigator.platform.indexOf("ipad") != -1)
    );
}


var getIOSWindowHeight = function() {
    // http://tripleodeon.com/assets/2011/12/table.html 
  
    // Get zoom level of mobile Safari
    // Note, that such zoom detection might not work correctly in other browsers
    // We use width, instead of height, because there are no vertical toolbars :)
    var zoomLevel = document.documentElement.clientWidth / window.innerWidth;

    // window.innerHeight returns height of the visible area. 
    // We multiply it by zoom and get out real height.
    return window.innerHeight * Math.round(zoomLevel);
};
var getIOSWindowWeight = function() {
    var zoomLevel = document.documentElement.clientWidth / window.innerWidth;
    return window.innerWidth * Math.round( zoomLevel );
};

/**
 * By default bg is 100% of width. 
 * Check that all interested points would show with default settings (arm and leg belts) 
 * and fit vertically on screen. 
 * 
 * if not - we have to chop down sides.
 * 
 */
function gbsSetupAnimationsFrameSizes( ) {
  
  var pxRatio = 1;
  
  if( gbsIsiPhone() ){
    var viewPortWidth = getIOSWindowWeight();
    var viewPortHeight = getIOSWindowHeight();
    
    try {
      if ( typeof window.devicePixelRatio !== 'undefined' ){
        pxRatio = Math.round( window.devicePixelRatio );
      }
    }  catch(err) {
      
    }
  } else {
    var viewPortWidth  = ( typeof window.outerWidth  != 'undefined') ? Math.min( window.outerWidth,  jQuery(window).width() ): jQuery(window).width();
    var viewPortHeight = ( typeof window.outerHeight != 'undefined') ? Math.min( window.outerHeight, jQuery(window).height() ): jQuery(window).height();
  }
  
  //jQuery( '#steps-animation-wrapper .bg-img' ).css( 'width', '100%' );
  var topBarAndTimerHeight = 120;
  var bottomButtonsHeight = 140;
  if( jQuery('.bar.bar-nav').outerHeight() > 0 && jQuery('.stopwatch-wrapper').outerHeight() > 0 ){
    topBarAndTimerHeight = parseInt( jQuery('.bar.bar-nav').outerHeight() ) + parseInt( jQuery('.stopwatch-wrapper').outerHeight() );
  }
  if( jQuery('.exercise-bottom-links').outerHeight() > 0 ){
    bottomButtonsHeight = parseInt( jQuery('.exercise-bottom-links').outerHeight() );
  }
  
  var bgData = gbsGetImgData( '.bg-img', viewPortWidth );
  var usableHeight = viewPortHeight - topBarAndTimerHeight - bottomButtonsHeight; // exclude timer / top bar etc
  
  var madeFit = false;
  
  if( usableHeight < ( bgData.actualLegsY - bgData.actualArmsY ) ){
    madeFit = true;
    var found = false;
    var testWidth = bgData.viewWidth;
    while ( !found) {
      testWidth = testWidth - 3;
      var bgDataTest = gbsGetImgData( '.bg-img', testWidth );
      if( usableHeight >= ( bgDataTest.actualLegsY - bgDataTest.actualArmsY ) ){
        bgData = gbsGetImgData( '.bg-img', testWidth );
        found = true;
      }
      if( testWidth < 60 ){
        // something ain't right. Quit
        found = true;
      }
    }
  }
  
  //center description boxes up
  var descriptionBoxHeight = 190;
  if( jQuery('.step-description:first').outerHeight() > 0 ){
    descriptionBoxHeight = parseInt( jQuery('.step-description:first').outerHeight() );
  }
  var centerY = ( usableHeight / 2.3 ) - ( descriptionBoxHeight / 2 );
  
  // center bg image up
  var top = -1 * bgData.actualArmsY;
  var legsArmsDistance= ( bgData.actualLegsY - bgData.actualArmsY );
  if( legsArmsDistance + 10 < usableHeight ){
    top = top + ( ( usableHeight - legsArmsDistance ) / 2 );
  }
  
  
  jQuery( '.step-description' ).css( 'top', centerY );
  
  var hpx = ( viewPortHeight ) - ( topBarAndTimerHeight );
  jQuery( '#steps-animation-wrapper .step' ).css( 'height', hpx );
  jQuery( '#steps-animation-wrapper' ).css( 'height', hpx );
  
  jQuery( '#steps-animation-wrapper' ).css( 'width', bgData.viewWidth );
  jQuery( '#steps-animation-wrapper .bg-img' ).css( 'top', top );
  
  // leg and arm pressures positioning
  var pressuresHeight = 70;
  if( jQuery('.step-description:first').outerHeight() > 0 ){
    pressuresHeight = parseInt( jQuery( '.pressure-arms' ).outerHeight() );
  }
  var tmpRatio = 1.7;
  if( usableHeight > bgData.viewWidth ){
    tmpRatio = 1;
  }
  
  var topArms = (top + bgData.actualArmsY) + (pressuresHeight /4 );
  jQuery( '.pressure-arms' ).css( 'top', topArms );
  
  var topLegs = (top + bgData.actualLegsY - (pressuresHeight * tmpRatio ) );
  jQuery( '.pressure-legs' ).css( 'top', topLegs );
}


function GbsExerciseAnimator ( ) {
  this.timeline = null;
  this.frameSwitchTime = 1.1;
  
  this.init = function() {
    var that = this;
    
  }

  this.initAnimation = function() {
    var that = this;
    
    var stepHydrate = document.getElementsByClassName( "step-hydrate" );
    var stepPrepareBelts = document.getElementsByClassName( "step-prepare-belts" );
    var stepPressures = document.getElementsByClassName( "step-pressures" );
    var stepRegimens = document.getElementsByClassName( "step-regimens" );
    
    var fst = this.frameSwitchTime;
    var tbf = 1.6; // Time between frames
    var tbrb = 0.15; // Time between red belt 
    
    this.timeline = new TimelineLite({onComplete:function() {
        this.restart();
      }
    });
    
    // Switch to prepare belts frame
    this.timeline.to( stepHydrate,        fst, { opacity : 0 }, "+=" + tbf );
    this.timeline.to( stepPrepareBelts,   fst, { opacity : 1 }, "-=" + fst );
    
    this.timeline.to( '.bg-img-2',    (fst / 2), { opacity : 1 }, "+=" + tbrb );
    this.timeline.to( '.bg-img-3',    (fst / 2), { opacity : 1 }, "+=" + tbrb );
    
    // Switch to set and show pressures
    this.timeline.to( stepPrepareBelts,   fst, { opacity : 0 }, "+=" + tbrb  );
    this.timeline.to( stepPressures,      fst, { opacity : 1 }, "-=" + fst );
    
    //reset step-prepare-belts to correct opacities
    this.timeline.to( ['.bg-img-2', '.bg-img-3'],    0, { opacity : 0 } );
    this.timeline.to( '.bg-img-1',    0, { opacity : 1 } );
    
    
    //this.timeline.fromTo( '.pressure-arms',   0.6, { opacity : 0, scale :  0.2 }, { opacity : 1, scale :  1 }, "+=0.6" );
    //this.timeline.fromTo( '.pressure-legs',   0.6, { opacity : 0, scale :  0.2 }, { opacity : 1, scale :  1 }, "+=0.3" );
    
    // Switch to regimen frame
    this.timeline.to( stepPressures,      fst, { opacity : 0 }, "+=" + (tbf * 2.6) );
    this.timeline.to( stepRegimens,       fst, { opacity : 1 }, "-=" + fst );
      
    // Show regimen lists one by one
    this.timeline.staggerFromTo( [ '.regimen-1', '.regimen-2', '.regimen-3', '.regimen-4' ], 1, { opacity : 0, scale : 2}, { opacity : 1, scale : 1 }, 1 );
    //this.timeline.to( '.regimen',       0.5, { opacity : 0 }, "+=1" );
    
    
    // Switch to first frame (hydrate)
    this.timeline.to( stepRegimens,       fst, { opacity : 0 }, "+=" + (tbf * 2) );
    this.timeline.to( stepHydrate,        fst, { opacity : 1 }, "-=" + fst );
    
  }

}


function gbsGetImgData( img, simulateWidth ) {
  
  var w = jQuery( img ).attr( 'data-original-width' );
  var h = jQuery( img ).attr( 'data-original-height' );
  var armY = jQuery( img ).attr( 'data-arms-height' );
  var legY = jQuery( img ).attr( 'data-legs-height' );
  
  if( simulateWidth != null ){
    var wr = simulateWidth;
    var hr = ( h / w ) * simulateWidth;
  } else {
    var wr = jQuery( img ).width();
    var hr = jQuery( img ).height();
  }
  
  var d = {
    width: w,
    height: h,
    viewWidth: wr,
    viewHeight: hr,
    armsY: armY,
    legsY: legY,
    ratio: ( h / w ),
    ratioArms: ( armY / w ),
    ratioLegs: ( legY / w )
  };
  
  d.actualArmsY = Math.floor( d.ratioArms * wr );
  d.actualLegsY = Math.floor( d.ratioLegs * wr );
  
  return d;
}




