
!(function () {
  'use strict';
  
  
  jQuery(document).ready(function () {

    jQuery( '#btn-program-start-now' ).click( function(){
      
      // var dialog = new gbsCustomDialog();
      // dialog.confirm(
      //   function(){ 
      //     jQuery( '#inp-action-program-start-now' ).val( 1 );
      //     jQuery( '#form-program-start' ).submit();
      //   },
      //   'CONFIRM',
      //   'You can only do one program at a time to have effective B STRONG Training.  Starting this new program will reset your progress and cancel you out of a program you might be currently doing',
      //   'CONTINUE ANYWAY',
      //   'CANCEL'
      // );

      // return false;


      jQuery( '#inp-action-program-start-now' ).val( 1 );
      jQuery( '#form-program-start' ).submit();
    });

    jQuery( '#btn-program-start-later' ).click( function(){
      
      // var dialog = new gbsCustomDialog();
      // dialog.confirm(
      //   function(){ 
      //     jQuery( '#inp-action-program-start-now' ).val( 0 );
      //     jQuery( '#form-program-start' ).submit();
      //   },
      //   'CONFIRM',
      //   'Your  scheduling  is  complete, your  first  session  will  start  Thursday,  July  25,  Enjoy!”',
      //   'CONTINUE',
      //   'CANCEL'
      // );

      // return false;


      jQuery( '#inp-action-program-start-now' ).val( 0 );
      jQuery( '#form-program-start' ).submit();


    });

    // if user changes time or date he can't anymore just press "start now". 
    // After that it's scheduled whatever date he inserts
    jQuery( '#btn-program-start-change-date' ).click( function( event ){
      //jQuery('#btn-program-start-now' ).fadeOut();
      //jQuery('#inp-program-start-now-date').val( 0 );
      jQuery('#display-program-start-date').fadeOut( 100, function(){ jQuery('#inp-program-start-date').fadeIn(); });
      
      var $input = jQuery('#inp-program-start-date').pickadate()
      var picker = $input.pickadate('picker')
      picker.open();
      event.stopPropagation();
      event.preventDefault();

      return false;
    });

    jQuery( '#btn-program-start-change-time' ).click( function( event ){
      //jQuery('#btn-program-start-now' ).fadeOut();
      //jQuery('#inp-program-start-now-time').val( 0 );
      jQuery('#display-program-start-time').fadeOut( 100, function(){ jQuery('#inp-program-start-time').fadeIn(); });
      
      var $input2 = jQuery('#inp-program-start-time').pickatime()
      var picker2 = $input2.pickatime('picker')
      picker2.open();
      event.stopPropagation();
      event.preventDefault();

      return false;
    });

    var minDate = jQuery('#inp-program-start-min-date').val( );

    jQuery('#inp-program-start-date').pickadate({
      format: "yyyy-m-d",
      min: minDate
    });
    jQuery('#inp-program-start-time').pickatime({
      interval: 5,
      format: "H:i"
    });

  });
  
  
}());

