

/**
 * Simple wrapper for communicating with API
 * requires jQuery
 * 
 */


function gbsCustomDialog () {
  this.endpoint = '';
  
  jQuery( '.gbs-custom-dialog .confirm' ).off();
  jQuery( '.gbs-custom-dialog .cancel' ).off();
  jQuery( '#gbs-custom-dialog' ).addClass( 'active' );
}


/**
 * Conrirm is a dialog with 2 buttonsand and confirmf action callback
 * 
 *  - Continue
 *  - Cancel
 * 
 * @param function cbConfirm
 * @return self
 */
gbsCustomDialog.prototype.confirm = function( cbConfirm, title, text, confirmText, cancelText ) {
  //this.confirmCallback = cbConfirm;
  var that = this;
  
  var title       = typeof title  == 'undefined' ? 'Please confirm'   : title;
  var text        = typeof text  == 'undefined' ? 'Please confirm'   : text;
  var confirmText = typeof confirmText == 'undefined' ? 'Continue' : confirmText;
  var cancelText  = typeof cancelText  == 'undefined' ? 'Cancel'   : cancelText;
  
  jQuery( '.gbs-custom-dialog .title' ).text( title );
  jQuery( '.gbs-custom-dialog .dialog-text' ).text( text );
  jQuery( '.gbs-custom-dialog .confirm' ).text( confirmText );
  jQuery( '.gbs-custom-dialog .cancel' ).text( cancelText );


  jQuery( '.gbs-custom-dialog .confirm' ).click( function(){
    cbConfirm();
    return false;
  });

  jQuery( '.gbs-custom-dialog .cancel' ).click( function(){
    $el = jQuery('.gbs-custom-dialog');
    if ($el.is(':visible')) {
      jQuery('.gbs-custom-dialog').slideDown(function () {
        jQuery(this).removeClass('active'); 
      });
    }
    return false;
  });


  return this;
}





if( typeof jQuery != 'undefined' ){
  jQuery(document).ready(function () {

    jQuery( '.gbs-custom-dialog-confirm-link' ).click( function(){
      
      var title = jQuery( this ).attr('data-gbs-custom-dialog-title');
      var txt   = jQuery( this ).attr('data-gbs-custom-dialog-text');
      var confirmTxt = jQuery( this ).attr('data-gbs-custom-dialog-button-confirm');
      var cancelTxt  = jQuery( this ).attr('data-gbs-custom-dialog-button-cancel');
      var followLink = jQuery( this ).attr('href');

      confirmTxt = !confirmTxt ? 'Confirm' : confirmTxt;
      cancelTxt  = !cancelTxt ?  'Cancel' : cancelTxt;
      
      var dialog = new gbsCustomDialog();

      dialog.confirm(
        function(){ document.location = followLink; },
        title,
        txt,
        confirmTxt,
        cancelTxt
      );

      return false;
    });


  });
}

 