

/**
 * 
 * 
 */


function gbsCustomTimer () {
  this.time = 0;
  this.timeAfterReset = 0;
  this.nsId = '';
  this.timerFunction = 'count-up';
  this.timerRunning  = false;
  this.updateInterval = 1000;
}


/**
 * 
 * 
 * @param string ns Namespace (#id's prefix)
 * @return self
 */
gbsCustomTimer.prototype.toggleStartResetButton = function( ) {
  
  if( this.timerRunning ){
    this.killTimer();
    this.timerRunning = false;
    this.time = this.timeAfterReset;
  } else {
    this.time = this.timeAfterReset;
    this.timerRunning = true;
    this.startTimer();
  }

  this.doUpdate();
}

gbsCustomTimer.prototype.pad2 = function ( number ) {
  return (number < 10 ? '0' : '') + number;
}

gbsCustomTimer.prototype.defaultFormatMilliseconds = function (millis) {
  var x, seconds, minutes, hours;
  x = millis / 1000;
  seconds = Math.floor(x % 60);
  x /= 60;
  minutes = Math.floor(x % 60);
  x /= 60;
  hours = Math.floor(x % 24);
  // x /= 24;
  // days = Math.floor(x);
  return [this.pad2(minutes), this.pad2(seconds)].join(':');
}


/**
 * 
 * 
 * @param string ns Namespace (#id's prefix)
 * @return self
 */
gbsCustomTimer.prototype.doUpdate = function( ) {
  
  startingSeconds = jQuery( '#' + this.nsId + '-time-display' ).html( 
    this.defaultFormatMilliseconds( this.time )
  );

  var btnTxt = jQuery( '#' + this.nsId + '-btn-start-reset' ).attr('data-text-start');
  if( this.timerRunning ){
    btnTxt = jQuery( '#' + this.nsId + '-btn-start-reset' ).attr('data-text-reset');
  }

  jQuery( '#' + this.nsId + '-btn-start-reset' ).html( btnTxt );

}



/**
 * 
 * 
 * @param string ns Namespace (#id's prefix)
 * @return self
 */
gbsCustomTimer.prototype.doTick = function( ) {
  
  if( !this.timerRunning ){
    return false;
  }

  if( this.timerFunction == 'count-up' ){
    this.time = this.time + this.updateInterval;
  } else {
    this.time = this.time - this.updateInterval;
    if( this.time < 1 ){
      this.timerRunning = false;
      this.time = 0;
      this.killTimer();
      this.counterReachedZero();
    }
  }

}


/**
 * 
 * 
 * @param string ns Namespace (#id's prefix)
 * @return self
 */
gbsCustomTimer.prototype.counterReachedZero = function( ) {
  var soundHandle = document.getElementById( this.nsId + '-sound-handle' );
  soundHandle.play();
}


/**
 * 
 * 
 * @param string ns Namespace (#id's prefix)
 * @return self
 */
gbsCustomTimer.prototype.startTimer = function( ) {
  var that = this;
  this.killTimer();
  this.timerID = setInterval( function() {
    that.doTick();
    that.doUpdate();
  }, that.updateInterval );

}


/**
 * 
 * 
 * @param string ns Namespace (#id's prefix)
 * @return self
 */
gbsCustomTimer.prototype.killTimer = function( ) {
  clearInterval( this.timerID );
}


/**
 * 
 * 
 * @param string ns Namespace (#id's prefix)
 * @return self
 */
gbsCustomTimer.prototype.setup = function( ns, countfunction, startMilliSeconds ) {
  var that = this;

  this.nsId = ns;
  this.timerFunction = countfunction;
  this.timeAfterReset = startMilliSeconds;
  this.time = startMilliSeconds;

  jQuery( '#' + ns + '-btn-start-reset' ).click( function(){
    that.toggleStartResetButton();
    return false;
  });

  this.doUpdate();

  return this;
}


/**
 * Automatically add functionality if found some classes with wrapping class "gbs-custom-timer"
 * 
 */
if( typeof jQuery != 'undefined' ){
  jQuery(document).ready(function () {

    jQuery( '.gbs-custom-timer' ).each( function(){
      
      var ns = jQuery( this ).attr( 'id' );
      var countingFunction = jQuery( '#' + ns + '-inp-function' ).val();
      var startingSeconds = jQuery( '#' + ns + '-inp-seconds-start' ).val();
      
      var defaultObj = new gbsCustomTimer();
      defaultObj.setup( ns, countingFunction, startingSeconds * 1000 );

      return false;
    });


  });
}
