var exerciseYtVideo = null;

!(function () {
  'use strict';
  
  var updateInProgress = false;
  var player;

  jQuery(document).ready(function () {
    jQuery( '#workout-parts-wrapper-start' ).click( function(){
      basilInstance.remove( 'timer_start_ts' );
      basilInstance.remove( 'timer_started' );
      console.log( 'reset timer' );
      console.log( basilInstance );
      jQuery( '#workout-parts-wrapper-form' ).submit();
    });
    jQuery( '#workout-parts-wrapper-done' ).click( function(){
      jQuery( '#workout-parts-wrapper-form' ).submit();
    });
    jQuery( '#workout-parts-wrapper-pause' ).click( function(){
      jQuery( '#workout-parts-wrapper-form' ).submit();
    });
    jQuery( '#workout-parts-wrapper-save-fatigue' ).click( function(){
      jQuery( '#workout-parts-wrapper-form' ).submit();
    });
  });
  
  /*
  jQuery('iframe[id^="youtube-player-"]').each(function (i) {
      jQuery(this)[0].contentWindow.postMessage('{"event":"command","func":"' + 'stopVideo' + '","args":""}', '*');

  });
  **/


  jQuery( window ).load(function () {
    jQuery( '.exercise-video' ).each( function(){
      loadYTVideo( jQuery( this ).attr( 'id' ), jQuery( this ).attr( 'data-youtube-id' ) );
    });
  });

  function loadYTVideo( divId, YTID ){
      //https://developers.google.com/youtube/player_parameters
      exerciseYtVideo = new YT.Player( divId , {
        videoId:  YTID,
        onStateChange : function (){
          if (playerStatus == 0 || playerStatus == 1) {
            exerciseYtVideo.seekTo(0, true)
            exerciseYtVideo.playVideo();
          }
        },
        playerVars: {
          controls: 0,
          autoplay: 1,
          loop: 1,
          disablekb: 1,
          enablejsapi: 1,
          iv_load_policy: 3,
          modestbranding: 1,
          showinfo: 0,
          events: {
            'onReady': function(){  },
            'onStateChange': function( event ){ }
          },
          rel: 0
        }
      });

  }


  jQuery( window ).load(function () {

    jQuery('#modal-assigned-sessions-opener').click( function(){
      jQuery( '#modal-assigned-sessions' ).addClass('active').css('display', 'block');
    } );

    jQuery('#modal-assigned-sessions-opener2').click( function(){
      jQuery( '#modal-assigned-sessions' ).addClass('active').css('display', 'block');
    } );

    jQuery('#modal-notes-to-assignee-opener').click( function(){
      var content = jQuery('#modal-notes-to-assignee-content').html();
      jQuery('#modal-notes-to-assignee .gbs-content').html( content );
      jQuery( '#modal-notes-to-assignee' ).addClass('active').css('display', 'block');
    } );
    
  });

  
}());

