

!(function () {
  'use strict';
  
  jQuery( document ).ready(function () {

    jQuery( '#exerciseeditor-equipment-list-add' ).on("click", function () {
      jQuery( '#equipment-picker-modal' ).addClass('active');
      return false;
    });

    jQuery( '#exerciseeditor-equipment-list-selected' ).on("click", '.remove', function () {
      jQuery( this ).parents('li').remove();
      return false;
    });

    jQuery( '#equipment-group-id' ).on("change", function () {
      filterEquipmentListByGroupId( jQuery(this).val() );
      return false;
    });
    
    jQuery( '#equipment-picker-list li' ).on("click", function () {
      var id   = jQuery( this ).attr( 'data-id' );
      var name = jQuery( this ).attr( 'data-name' );

      if( addToSelectedList( id, name ) ){
        setTimeout( function(){
          jQuery( '#equipment-picker-modal' ).slideDown(function () {
              jQuery(this).removeClass('active');
          });
        }, 250 );
      }


      return false;
    });

    filterEquipmentListByGroupId( '' );

  });

  function filterEquipmentListByGroupId( key ){
    
    jQuery( '#equipment-picker-list li' ).each( function(){
      var groups = jQuery( this ).attr( 'data-group-ids' );
      
      var found = 1;

      if( key != '' ){
        found = groups.indexOf( key );
      }

      if( found < 0 ){
        jQuery( this ).hide();
      } else {
        jQuery( this ).show();
      }

    });

  }

  function addToSelectedList( id, name ){
    //check that it's not alreay added
    var alreadyAdded = false;

    jQuery( '#exerciseeditor-equipment-list-selected li' ).each( function(){
      var tid   = jQuery( this ).attr( 'data-id' );
      console.log( tid + '/' + id );
      
      if( tid == id){
        alreadyAdded = true;
      }
    });

    if( !alreadyAdded ){
      var html = '<li class="table-view-cell" data-id="' + id + '" >';
      html = html + name + '<a href="#" class="remove">&times;</a>';
      html = html + '<input type="hidden" name="equipment_ids[]" value="' + id + '" /><li>';

      jQuery( '#exerciseeditor-equipment-list-selected ul' ).append( html );
    } else {
      alert('Already added');
      return false;
    }
    
    return true;
  }

  
  
}());

