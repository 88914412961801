

/**
 * 
 * 
 */


function gbsFormToggler () {
  this.togglerSelector = '';
  this.targetFormSelector = '';
  this.toggleValues = [];
}


/**
 * 
 * 
 * @param string ns Namespace (#id's prefix)
 * @return self
 */
gbsFormToggler.prototype.setup = function( selector ) {
  var that = this;

  this.togglerSelector = '#' + selector;
  this.targetFormSelector  = jQuery( this.togglerSelector ).attr( 'data-target' );
  this.toggleValues = this.getTogglerValues( this.togglerSelector );
  
  jQuery( this.togglerSelector ).click( function(){
    that.toggleFormFields();
    return false;
  });
  
}

gbsFormToggler.prototype.toggleFormFields = function () {
  var that = this;

  //
  //toggle-input

  jQuery( this.targetFormSelector + ' .toggle-field .toggle-input' ).each( function(){
    var currentValue = jQuery( this ).val();
    
    var current = null;
    var first = null;
    var next = null;
    var count = 0;

    for( var i in that.toggleValues ){
      count++;
      if( count == 1 ){
        first = that.toggleValues[ i  ];
      }
      if( current != null && next == null ){
        next = that.toggleValues[ i ];
      }
      if( current == null && that.toggleValues[ i ].key == currentValue ){
        current = that.toggleValues[ i ];
      }
    }

    if( next == null ){
      next = first;
    }

    if( next == null ){
      alert( 'Error in toggle. Please reload page' );
    }

    jQuery( this ).val( next.key );
    jQuery( this ).parents('.toggle-field').find('.toggle-display').html( ' (' + next.value + ')' );
  });
}


gbsFormToggler.prototype.getTogglerValues = function ( sel ) {
  var toggleValues = [];
  
  var keys    = jQuery( sel ).attr( 'data-toggle-keys' );
  var names   = jQuery( sel ).attr( 'data-toggle-names' );

  var keysExtracted = keys.split('|');
  var valuesExtracted = names.split('|');
  
  for( var i in keysExtracted ){
    toggleValues[ toggleValues.length ] = {
      key : keysExtracted[ i ],
      value : valuesExtracted[ i ]
    }
  }

  if( toggleValues.length < 2 ){
    alert( 'More values needed fo toggle in ' + sel );
  }

  return toggleValues; 
}


/**
 * Automatically add functionality if found some classes
 * 
 */
if( typeof jQuery != 'undefined' ){
  jQuery(document).ready(function () {

    jQuery( '.gbs-toggle-form-fields' ).each( function(){
      
      var id = jQuery( this ).attr( 'id' );

      var defaultObj = new gbsFormToggler( );
      defaultObj.setup( id );

      return false;
    });


  });
}
