

/**
 * 
 * 
 */


function gbsBeepsTimer () {
  this.nsId = '';

  this.updateInterval = 1000;
  this.beforeStartMilliSeconds = 5000;
  this.stages = [];
  this.callbacks = function(){};

  this.timerRunning  = false;
  this.timerTime = 0;
  this.startupCountDownCount = 5;
  this.testRunState = ''; // is it resting, stage or startup countdown- or pause
  this.currentStage = 0;
  this.currentStageObject = null;
}


/**
 * 
 * 
 * @param string ns Namespace (#id's prefix)
 * @return self
 */
gbsBeepsTimer.prototype.toggleStartResetButton = function( ) {
  
  if( this.timerRunning ){
    this.testRunState = 'finished';
    this.killTimer();
    this.timerRunning = false;
    this.doUpdate();
  } else {
    this.resetBeepTest( true );
    this.callbacks( 'started-start-countdown', null);
    this.timerRunning = true;
    this.startTimer();

    jQuery( '.fitness-beeps-test-frame').show();
  }

  this.doUpdate();
}

gbsBeepsTimer.prototype.pad2 = function ( number ) {
  return (number < 10 ? '0' : '') + number;
}

gbsBeepsTimer.prototype.defaultFormatMilliseconds = function (millis) {
  var x, seconds, minutes, hours;
  x = millis / 1000;
  seconds = Math.floor(x % 60);
  x /= 60;
  minutes = Math.floor(x % 60);
  x /= 60;
  hours = Math.floor(x % 24);
  // x /= 24;
  // days = Math.floor(x);
  return [this.pad2(minutes), this.pad2(seconds)].join(':');
}


/**
 * When timer is running, this gets called every time 1 seconds passes, after time and states update
 * 
 * @param string ns Namespace (#id's prefix)
 * @return self
 */
gbsBeepsTimer.prototype.doUpdate = function( ) {
  
  //Timer display update
  startingSeconds = jQuery( '#' + this.nsId + '-time-display' ).html( 
    this.defaultFormatMilliseconds( this.timerTime )
  );

  //Button text when timer running
  var btnTxt = jQuery( '#' + this.nsId + '-btn-start-reset' ).attr('data-text-start');
  if( this.timerRunning ){
    btnTxt = jQuery( '#' + this.nsId + '-btn-start-reset' ).attr('data-text-finish');
  }
  jQuery( '#' + this.nsId + '-btn-start-reset' ).html( btnTxt );

  //Stage view update
  if( this.testRunState  == 'startup-countdown' ){
    jQuery( '.fitness-beeps-test-frame .stage-text-number').text( this.startupCountDownCount );
  }
  if( this.testRunState  == 'stage-started' ){
    jQuery( '.fitness-beeps-test-frame .stage-text-title').text( 'STAGE' );
    jQuery( '.fitness-beeps-test-frame .stage-text-number').text( this.currentStage );
  }
  if( this.testRunState  == 'stage-rest' ){
    var next = this.currentStageObject.stageNr + 1;
    jQuery( '.fitness-beeps-test-frame .stage-text-title').text( 'STAGE ' + next + ' starting in 7 seconds' );
    jQuery( '.fitness-beeps-test-frame .stage-text-number').html( '&nbsp;' );
  }
  if( this.testRunState  == 'finished' ){
    var next = this.currentStageObject.stageNr + 1;
    jQuery( '.fitness-beeps-test-frame .stage-text-title').text( 'STAGES FINISHED' );
    jQuery( '.fitness-beeps-test-frame .stage-text-number').text( this.currentStage );
  }


}



/**
 * When timer is running, this gets called every time 1 seconds passes
 * 
 * @param string ns Namespace (#id's prefix)
 * @return self
 */
gbsBeepsTimer.prototype.doTick = function( ) {
  
  if( !this.timerRunning ){
    return false;
  }

  this.timerTime = this.timerTime - this.updateInterval;
  
  if( this.testRunState  == 'startup-countdown' ){
    this.doStartupCountDown();
  }
  if( this.testRunState  == 'stage-started' ){
    this.doStageCountDown();
  }
  if( this.testRunState  == 'stage-rest' ){
    this.doRestingCountDown();
  }

}


/**
 * After start screen is setup- do a count down to first stage
 * 
 * @return void
 */
gbsBeepsTimer.prototype.doStartupCountDown = function() {
  this.playBeep( false );

  this.startupCountDownCount--;
  if( this.startupCountDownCount < 0 ){
    this.setupNextStage( 1 );
  }
}


/**
 * After stage is setup- do a count down
 * 
 * @return void
 */
gbsBeepsTimer.prototype.doStageCountDown = function() {
  var that = this;
  
  if( this.timerTime < 1 ){
    that.playBeep( true );
    this.setupRestTime();
  }

  if( this.halfWayBeepDone ){
    return;
  }
  
  var secondsLeft = this.timerTime / 1000;
  if( (secondsLeft -1) < this.currentStageObject.countDownHalf ){
    var timeLeft = this.currentStageObject.countDownHalf - secondsLeft;
    setTimeout( function(){
      that.playBeep( false );
    }, timeLeft );
    
    this.halfWayBeepDone = true;
  }
}


/**
 * Play regular or double beep sound
 * 
 * @return void
 */
gbsBeepsTimer.prototype.doRestingCountDown = function() {
  
  if( this.timerTime < 5000 ){
    this.playBeep( false );
  }

  if( this.timerTime <= 0 ){
    var nr = this.currentStage + 1;
    var stage = this.findstage( nr );

    //if max stage- stop timer
    if( stage == null ){
      this.testRunState = 'finished';
      this.callbacks( 'finished', null);
      this.timerRunning = false;
      this.killTimer();
      return;
    }
    this.setupNextStage();
  }
}


/**
 * Setup/start given stage or next stage
 * 
 * @return void
 */
gbsBeepsTimer.prototype.setupNextStage = function( nr ) {

  if( nr == null ){
    nr = this.currentStage + 1;
  }

  var stage = this.findstage( nr );


  this.currentStageObject = stage;

  
  this.testRunState = 'stage-started';
  this.currentStage = nr;
  this.timerRunning  = true;
  this.timerTime = stage.countDown * 1000;

  this.halfWayBeepDone = false;
  this.testRunState = 'stage-started';
  this.callbacks( 'stage-started', null);
  this.playBeep( true );
}


/**
 * Setup rest time from current stage info
 * 
 * @return void
 */
gbsBeepsTimer.prototype.setupRestTime = function() {
  this.timerTime = this.currentStageObject.countDownRest * 1000;
  this.testRunState = 'stage-rest';
}


/**
 * Play regular or double beep sound
 * 
 * @return void
 */
gbsBeepsTimer.prototype.playBeep = function( double ) {
  var that = this;
  
  var soundHandle = document.getElementById( this.nsId + '-sound-handle' );
  soundHandle.play();
  
  if( double ){
    setTimeout( function(){
      var soundHandle2 = document.getElementById( that.nsId + '-sound-handle-2' );
      soundHandle2.play();
    }, 200 );
  }
}


/**
 * 
 * 
 * @param string ns Namespace (#id's prefix)
 * @return self
 */
gbsBeepsTimer.prototype.startTimer = function( ) {
  var that = this;
  this.killTimer();
  
  this.timerID = setInterval( function() {
    that.doTick();
    that.doUpdate();
  }, that.updateInterval );

}


/**
 * 
 * 
 * @param string ns Namespace (#id's prefix)
 * @return self
 */
gbsBeepsTimer.prototype.killTimer = function( ) {
  clearInterval( this.timerID );
}


/**
 * 
 * 
 * @param integer stageNr
 * @return object
 */
gbsBeepsTimer.prototype.findstage = function( stageNr ) {
  for( var i in this.stages ){
    if( this.stages[ i ].stageNr == stageNr){
      return this.stages[ i ];
    }
  }

  return null;
}



/**
 * Resets everything to startin position of test
 * 
 * @param string ns Namespace (#id's prefix)
 * @return self
 */
gbsBeepsTimer.prototype.resetBeepTest = function( firstTime ) {
  clearInterval( this.timerID );

  this.testRunState = 'startup-countdown';
  this.currentStage = 0;
  this.timerRunning  = false;
  this.timerTime = this.beforeStartMilliSeconds;
  this.startupCountDownCount = this.beforeStartMilliSeconds / 1000;

  jQuery( '.fitness-beeps-test-frame .stage-text-title').text( 'STAGE 1 starting in 5 seconds' );

  if( !firstTime ){
    this.callbacks( 'reset', null);
  }

}


/**
 * 
 * 
 * @param string ns Namespace (#id's prefix)
 * @return self
 */
gbsBeepsTimer.prototype.setup = function( ns, stages, beforeStartMilliSeconds, callbacks ) {
  var that = this;

  this.nsId = ns;
  this.callbacks = callbacks;
  this.beforeStartMilliSeconds = beforeStartMilliSeconds;
  this.stages = stages;

  
  jQuery( '#' + ns + '-btn-start-reset' ).click( function(){
    that.toggleStartResetButton();
    return false;
  });

  this.resetBeepTest( true );
  this.doUpdate();

  return this;
}

