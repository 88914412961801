
!(function () {
  'use strict';
  
  var firstTimePopUp = true;
  
  jQuery(document).ready(function () {
    
    jQuery('#pressures-estimator-belt-color-choose').on('change', function() {
      //var pattern = jQuery( '#pressures-estimator-chart-img' ).attr( 'data-path-pattern' );
      var color   = jQuery( this ).val();
      
      //pattern = pattern.replace( '[[color]]', color );
      
      jQuery( '.pressure-estimator-chart' ).not( ".hidden" ).addClass( 'hidden' );
      jQuery( '#chart-table-' + color ).removeClass( 'hidden' );
      
      //jQuery( '#pressures-estimator-chart-img' ).fadeTo( 'fast', 0.55, function(){
      //  jQuery( '#pressures-estimator-chart-img' ).attr( 'src', pattern ).fadeTo( 'fast', 1 );
      //} );
    });
    
    jQuery( '#pressures-estimator-specific-open' ).on('click', function( ) {
      
      /*
      var delayScroll = setTimeout( function(){
        var top = parseInt( jQuery( '#pressures-estimator-specific-wrapper' ).offset().top, 10);
        jQuery('html,body').stop(true,true).animate( { scrollTop : top }, 400 );
        jQuery( this ).fadeOut( 'slow' );
      }, 200 );
      */
      
      jQuery( this ).fadeOut( 'slow' );
      
    });
    
    if( jQuery( '#pressures-estimator-specific-wrapper' ).lenght < 1 ){
      return;
    }
    
    jQuery('#pressures-estimator-specific-wrapper .inp-field input').on('change', function() {
      updatePressuresEstimatorSpecificResults();
    });
    jQuery('#pressures-estimator-specific-wrapper .inp-field input').on('keyup', function() {
      updatePressuresEstimatorSpecificResults();
    });
    jQuery('#pressures-estimator-specific-wrapper .inp-field select').change(function() {
      var delayTimer = setTimeout( function(){
        updatePressuresEstimatorSpecificResults();
      }, 200 );
    });
    
  });
  
  
  function updatePressuresEstimatorSpecificResults(){
    var paramObj = getPressuresEstimatorSpecificFormData();
    var pressure = 100; // To be filled with correct data
    
    var currentPressureArm = jQuery( '#pressures-estimator-specific-result-arm' ).val( );
    var currentPressureLeg = jQuery( '#pressures-estimator-specific-result-leg' ).val( );
    
    var pressureArm = gbsCalculatePressure( true, paramObj.armBeltColor, paramObj );
    var pressureLeg = gbsCalculatePressure( true, paramObj.legBeltColor, paramObj );
    
    jQuery( '#pressures-estimator-specific-result-arm' ).val( pressureArm );
    jQuery( '#pressures-estimator-specific-result-leg' ).val( pressureLeg );
    
    if( firstTimePopUp ){
      jQuery( '#specified-estimamator-results' ).animate( { bottom : 0 }, 500, function(){
      } );
      firstTimePopUp = false;
    } else {
      if( pressureArm != currentPressureArm ){
      }
      if( pressureLeg != currentPressureLeg ){
      }
    }
  }
  
  function getPressuresEstimatorSpecificFormData(){
    
    var inp = {
      'age' : jQuery( '.input-age input' ).val(),
      'exercise' : jQuery( '.radios-exercise input:checked' ).val(),
      //'geneder' : jQuery( '.radios-geneder input:checked' ).val(),
      'healthy' : jQuery( '.radios-healthy input:checked' ).val(),
      'intensityLevel' : jQuery( '.radios-intensity-level input:checked' ).val(),
      'armBeltColor' : jQuery( '#pressures-estimator-specific-belt-arm-choose' ).val(),
      'legBeltColor' : jQuery( '#pressures-estimator-specific-belt-leg-choose' ).val(),
    };
    
    return inp;
  }
  
  
}());


function gbsCalculatePressure( first, beltColor, paramObj ){
  var pressure = 0;
  
  for( var i in beltNormalPressuresBycolors ){
    if( beltNormalPressuresBycolors[i].color == beltColor ){
      pressure = beltNormalPressuresBycolors[i].pressure;
    }
  }
  
  if ( first ) {
    pressure = pressure -20;
  }
  
  if (paramObj.age > 50) {
      pressure -= 20;
  }

  if (paramObj.healthy == 0) {
      pressure -= 20;
  }

  if (paramObj.exercise == 0) {
      pressure -= 20;
  }

  if (paramObj.intensityLevel == 1) {
      pressure += 20;
  } else if (paramObj.intensityLevel == 2) {
      pressure += 40;
  }

  return Math.round( pressure / 5 ) * 5;
}


