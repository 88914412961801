
var globalYoutubePlayers = [];


!(function () {
  'use strict';
  
  jQuery( document ).ready(function () {
    
    // switch label on registering page
    jQuery('#register-units-input-wrapper input').on('change', function() {
      var units = jQuery( '#register-units-input-wrapper input:checked' ).val();
      
      jQuery( '.register-unit' ).removeClass( 'selected' );
        
      if( units == 1 ){
        jQuery( '.register-unit.kg-cm' ).addClass( 'selected' );
      } else {
        jQuery( '.register-unit.inch' ).addClass( 'selected' );
      }
      
    });
    
    // jQuery('#warnings-form-submit').on( 'click', function( e ) {
    //   e.preventDefault();
    //   // Actually getElementById inline makes form to submit most of times- 
    //   // videos load too long to trigger doc ready
    //   jQuery('#warningns-form').submit();
      
    //   return false;
    // });
    
    jQuery('.register-how-to-measure-video-link').on('click', function() {
      jQuery('.register-how-to-measure-video-wrap').slideToggle();
    });
    
    gbsTabsInit();
    
    gbsWorkoutAndExercisesEditor();
    
    gbsTrainingGroupsContent();

    gbsWorkoutsContent();

    gbsListSelector();

    gbsUsersAndProfiles();
    
    gbsYoutubePreviewsLoad( '' );

    gbsUpgradeAccountModal( );

    if( jQuery('#auto-open-assigned-workouts-and-programs-list').length > 0 && jQuery('#auto-open-assigned-workouts-and-programs-list').val() > 0 ){
      var openTab = jQuery('#auto-open-assigned-workouts-and-programs-list').attr( 'data-tab-id' ) ;
      
      var openTab = !openTab ? 'workouts' : openTab;
      jQuery('#modal-assigned-sessions').addClass('active');

      jQuery( '#modal-assigned-sessions .tab-' + openTab ).trigger('click');
    }

    jQuery( '.modal-where-is-product-key-opener' ).on("click", function () {
      jQuery( '#modal-where-is-product-key' ).addClass('active');
    });

    //global modals closer
    jQuery( '.gbs-modal-close' ).on("click", function () {
      var targetId = '#' + jQuery( this ).parents( '.modal' ).attr( 'id' );
      var $el = jQuery(targetId);
      if ($el.is(':visible')) {
          jQuery(targetId).slideDown(function () {
              jQuery(this).removeClass('active');
          });
      }
    });

    jQuery( '#btn-exercises-filtering' ).on("click", function () {
      var fbns = 'exercises-filtering';
      
      gbsUpdateWorkoutViaAjax( fbns, null, null, function( data ){

        jQuery( '#exercises-filtering-results' ).html( data.htmlResult );

        gbsYoutubePreviewsLoad( '#exercises-filtering-results' );

        jQuery('#main-title').text( jQuery('#main-title-after-exercises-search').val() );
        jQuery('#back-button').attr( 'href', jQuery('#back-link-after-exercises-search').val() );

        setTimeout( function(){
          jQuery( '#exercises-modal-filter-form' ).slideDown(function () {
              jQuery(this).removeClass('active');
          });
        }, 400 );

        
      } );
    });

    jQuery('#fatigue-form-submit').click(function (e) {
        e.preventDefault();
        jQuery('#fatigue-form').submit();
    });


    jQuery( '.toggle-exercise-stat-unit' ).on("click", function () {
      var id = jQuery( this ).attr( 'data-id' );
      var optionsStr = jQuery( this ).attr( 'data-options' );
      var options = optionsStr.split(';');
      var currentUnit = jQuery( '#' + id + '-unit' ).val();
      // var currentUnit = jQuery( id + '-label-unit' ).val();

      var found = false;

      for( var i in options ){
        if( currentUnit != options[i] && !found ){
          currentUnit = options[i];
          found = true;
        }
      }

      var currentUnitLabel = currentUnit;
      if( currentUnit == 'pound' ){
        currentUnitLabel = 'Lbs';
      }
      if( currentUnit == 'kilogram' ){
        currentUnitLabel = 'Kg';
      }

      jQuery( '#' + id + '-unit' ).val( currentUnit );
      jQuery( '#' + id + '-label-unit' ).text( ' (' + currentUnitLabel + ')' );
    });

    
  });
  
}());

function gbsTabsInit() {
  
  jQuery('.gobstrong-tabs .tab-item').on('click', function() {
    var target = jQuery( this ).attr( 'data-target' );
    var gclass = jQuery( this ).attr( 'data-content-general-class' );
    var href   = jQuery( this ).attr( 'href' );
    var title  = jQuery( this ).attr( 'data-title' );


    if (title != '' && title != undefined){
      $("#main-title").html(title.toUpperCase())
    }else{
      
      var nTitle = target.replace("#tab-content-", "");
      var nTitle = nTitle.replace("-", " ");

      if (window.location.href.indexOf('exercise') == -1){
        $("#main-title").html(nTitle.toUpperCase())
      }
    }

    if( href != '#' ){
      return true;
    }
    
    jQuery( this ).parents( '.gobstrong-tabs' ).find( '.tab-item' ).removeClass( 'active' );
    jQuery( this ).addClass( 'active' );
    
    jQuery( gclass ).hide( );
    jQuery( target ).show( );
    
    //jQuery( gclass ).fadeTo( 'fast', 0.55, function(){
    //  jQuery( target ).attr( 'src', pattern ).fadeTo( 'fast', 1 );
    //} );
    
  });
  
}


function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}


function gbsYoutubePreviewsLoad( preSelector ) {

  jQuery( preSelector + ' .btn-exercise-preview').on('click', function() {
    var videoId  = jQuery(this).attr('data-preview-youtube-id');
    var target   = jQuery(this).attr('data-preview-wrapper-id');
    var ytIndex  = jQuery(this).attr('data-youtube-index');
    var that = this;

    jQuery( '#' + target ).slideToggle( 500, function(){
      if( jQuery( '#' + target + ":visible" ).length < 1 ){
        globalYoutubePlayers[ytIndex].stopVideo();
        globalYoutubePlayers[ytIndex].destroy();
      } else {
        var nextIndex = globalYoutubePlayers.length;
        jQuery( that ).attr('data-youtube-index', nextIndex);

        globalYoutubePlayers[ nextIndex ] =  new YT.Player( target + '-youtube-player', {
          videoId: videoId,
          playerVars: {
            controls: 1,
            autoplay: 1,
            loop: 1,
            disablekb: 1,
            enablejsapi: 1,
            iv_load_policy: 3,
            modestbranding: 1,
            showinfo: 0,
            rel: 0
          }
        });
      }
    
    } );

  });
}















function gbsWorkoutAndExercisesEditor() {
  
  jQuery( '.workout-btn-apply-part-params' ).on("click", function () {
    var partId = jQuery( this ).attr( 'data-workout-part-id' );

    var fbns = 'workout-form-part-params-' + partId;
    gbsUpdateWorkoutViaAjax( fbns, null, null, function( data ){
      $el = jQuery('#myModalexample-' + partId);
      if ($el.is(':visible')) {
          jQuery('#myModalexample-' + partId).slideDown(function () {
              jQuery(this).removeClass('active');
              
              jQuery( '#exercise-time-' + partId ).html( data.newTimeDuration );

              gbsUpdateWorkoutPartItem( partId );
          });
      }
    } );

  });
  
  jQuery( '.workout-part-btn-edit-parameters' ).on("click", function () {
    var partId = jQuery( this ).attr( 'data-workout-part-id' );
    var selector = jQuery( this ).attr( 'href' );
    jQuery('.alert').hide();
    jQuery( '#myModalexample-' + partId ).addClass('active');
  });
  
  jQuery( '#workout-btn-add-exercise' ).on("click", function () {
    jQuery( '#add-new-exercises-modal' ).addClass('active');
  });
  
  jQuery( '#workout-btn-edit-save' ).on("click", function () {
    jQuery('#workout-edit-form').submit();
  });


  jQuery( '#exercises-modal-filter-form-opener' ).on("click", function () {
    jQuery( '#exercises-modal-filter-form' ).addClass('active');
  });


  //

  jQuery( '#exercises-filtering-results' ).on("click", ".btn-add-exercise-to-library", function () {
    var that = this;
    var fbns = jQuery( this ).attr( 'data-ns' );
    var url = jQuery( this ).attr( 'href' );
    var postData = {
      '_token' : jQuery('#exercises-filtering-form input[name=_token]').val()
    };

    gbsUpdateWorkoutViaAjax( fbns, url, postData, function( data ){
      var fbns2 = fbns;
      setTimeout( function(){
        jQuery( '#' + fbns2 + '-feedback .alert' ).fadeOut();
      }, 1200 );
      jQuery( that ).text( jQuery(that).attr('data-after-button-success-text') );
    } );

    return false;
  });
  
  jQuery( '#workout-btn-add-selected-exercise' ).on("click", function () {
    var fbns = 'workout-add-selected-exercises';
    gbsUpdateWorkoutViaAjax( fbns, null, null, function( data ){
      setTimeout( function(){
        jQuery( '#add-new-exercises-modal' ).slideDown(function () {
            jQuery(this).removeClass('active');
        });
        window.location.reload();
      }, 1000 );
    } );
  });


  jQuery( '.move-up-down .btn-move' ).on("click", function () {
    var postData = {
      '_token' : jQuery('#workout-edit-form input[name=_token]').val()
    };
    gbsUpdateWorkoutViaAjax( '', jQuery(this).attr('href'), postData, function( data ){
      setTimeout( function(){
        window.location.reload();
      }, 500 );

    } );
    return false;
  });
  
}


function gbsUpdateWorkoutPartItem( partId ) {
  
  var newReps = jQuery( '#inp-new-reps-' + partId ).val();
  jQuery( '#inp-reps-' + partId ).val( newReps );
  jQuery( '#meta-value-reps-' + partId ).html( newReps );
  
  var newSets = jQuery( '#inp-new-sets-' + partId ).val();
  jQuery( '#inp-sets-' + partId ).val( newSets );
  jQuery( '#meta-value-sets-' + partId ).html( newSets );

  var newDurationRest = jQuery( '#inp-new-duration_rest-' + partId ).val();
  jQuery( '#inp-duration_rest-' + partId ).val( newDurationRest );
  jQuery( '#meta-value-duration_rest-' + partId ).html( newDurationRest );

  // var newDurationRep = jQuery( '#inp-new-duration_rep-' + partId ).val();
  // jQuery( '#inp-duration_rep-' + partId ).val( newDurationRep );
  // jQuery( '#meta-value-duration_rep-' + partId ).html( newDurationRep );

  var newWorkingTime = jQuery( '#inp-new-working_time-' + partId ).val();
  jQuery( '#inp-working_time-' + partId ).val( newWorkingTime );
  jQuery( '#meta-value-working_time' + partId ).html( newWorkingTime );
}


/* TODO: needs refactoring, because used by many requests in workout editing and possibly elswhere too- unify design and make it global  */
function gbsUpdateWorkoutViaAjax( fbns, url, formData, successCallback ) {
  // update workout part via ajax request and 
  // show some feedback/errors if occured
  
  jQuery( '#' + fbns + '-error' ).hide();
  jQuery( '#' + fbns + '-success' ).hide();
  jQuery( '#' + fbns + '-loading' ).show();
  
  if( formData == null ){
    formData = jQuery( '#' + fbns + '-form' ).serialize();
  }
  if( url == null ){
    url = jQuery( '#' + fbns + '-form' ).attr('action');
  }

  jQuery.ajax({
    data: formData,
    type: 'post',
    dataType: 'json',
    url: url,
    complete: function (data) {
      jQuery( '#' + fbns + '-loading' ).hide();
    },
    success: function (data) {
      if( data.success > 0 ){
        jQuery( '#' + fbns + '-success' ).html( data.successMessage ).show();
        successCallback( data );
      } else {
        if( fbns == '' && data.errorMessage != '' ){
          alert( data.errorMessage );
        }
        jQuery( '#' + fbns + '-error' ).html( data.errorMessage ).show( );
      }
    },
    error: function () {
      jQuery( '#' + fbns + '-error' ).html( jQuery( '#' + fbns + '-error' ).attr('data-general-error') ).show( );
    }
  });

}









function gbsWorkoutsContent() {

  jQuery( '#workouts-select-workout' ).on("change", function () {
    var selectedWorkoutId = jQuery(this ).val();
    var url = jQuery(this ).attr('data-base-url');
    document.location = url + selectedWorkoutId;
  });

  multiSelectorFromTo( {
    'from' : '#traininggroups-possible',
    'to' : '#traininggroups-selected',
    'btnAdd' : '#edit-traininggroup-add',
    'btnRemove' : '#edit-traininggroup-remove',
    'resultInput' : '#edit-traininggroup-selected-ids'
  } );
  

}

function gbsTrainingGroupsContent() {

  jQuery( '#training-groups-select-group' ).on("change", function () {
    var selectedId = jQuery(this ).val();
    var url = jQuery(this ).attr('data-base-url');
    document.location = url + selectedId;
  });


  

}




/* multiple selector logics */
function multiSelectorFromTo( optionsIn ){
  var thatOptionsIn = optionsIn;

  //var selected = $('.user_ids option:selected');
  //selected.appendTo('.possible');
  jQuery( optionsIn.from + ' li, ' + optionsIn.to + ' li' ).on("click", function () {
    var id = jQuery( this ).attr('data-value-id');
    if( jQuery(this).hasClass('selected') ){
      jQuery(this).removeClass('selected');
    } else {
      jQuery(this).addClass('selected');
    }
  });

  jQuery( optionsIn.btnAdd ).on("click", function () {
    multiSelectorMoveSelectedOptions( optionsIn.from, optionsIn.to );
    multiSelectorUpdateToField( thatOptionsIn.to, thatOptionsIn.resultInput );
  });
  jQuery( optionsIn.btnRemove ).on("click", function () {
    multiSelectorMoveSelectedOptions( optionsIn.to, optionsIn.from );
    multiSelectorUpdateToField( thatOptionsIn.to, thatOptionsIn.resultInput );
  });

}

function multiSelectorMoveSelectedOptions( from, to ){
  jQuery( from + ' li' ).each( function(){
    var id = jQuery( this ).attr('data-value-id');

    if( jQuery(this).hasClass('selected') ){
      jQuery(this).removeClass('selected');
      jQuery(this).appendTo( to ) 
    }

  });

}

function multiSelectorUpdateToField( toList, toInput ){
  var selected = '';
  console.log( toInput );
  jQuery( toList + ' li' ).each( function(){
    var id = jQuery( this ).attr('data-value-id');
    selected = selected + ( selected == '' ? '' : ',') + id;
  });
  jQuery( toInput ).val( selected );
}









/* list selector logics */

var gbsSelectedItems = [];
function gbsListSelector() {

  jQuery( '#exercises-filtering-results' ).on("click", ".list-select-item input", function () {
    var id = jQuery( this ).attr( 'data-list-id' );
    var checked = jQuery( this ).prop( 'checked' );
    
    gbsSelectedItems = gbsListSelectorRemoveArrayItemIfFound( gbsSelectedItems, id );

    if( checked ){
      gbsSelectedItems[ gbsSelectedItems.length ] = id;
    }

    var joinedIds = gbsSelectedItems.join( ',' );
    jQuery( this ).parents( '.list-selector-wrapper' ).find( '.selected_ids' ).val( joinedIds );
  });
  
}


function gbsListSelectorRemoveArrayItemIfFound(arr, value) {
  for(var i = 0; i < arr.length; i++) {
    if(arr[i] === value) {
      arr.splice(i, 1);
      break;
    }
  }
  return arr;
}

/* list selector logics end */






function gbsUsersAndProfiles() {
  
  jQuery( '#users-hub-user-selector' ).on("change", function () {
    var selectedWorkoutId = jQuery(this ).val();
    var url = jQuery(this ).attr('data-base-url');
    document.location = url + selectedWorkoutId;
  });

  if( jQuery( '#invite-email-search' ).length > 0 ){
    var input = document.getElementById("invite-email-search");
    var url = jQuery( '#invite-email-search' ).attr('data-search-url');
    var awesomplete = new Awesomplete(input, {
      minChars: 1,
      maxItems: 5,
      autoFirst: true
    });
    awesomplete.list = [];

    window.addEventListener("awesomplete-selectcomplete", function(e){
      console.log( jQuery( '#invite-email-search' ).val() );
    }, false);

    jQuery( '#invite-email-search' ).on('keyup', function(e) {
      var code = (e.keyCode || e.which);
      var special = ( code === 37 || code === 38 || code === 39 || code === 40 || code === 27 || code === 13 );
      var ajax = new XMLHttpRequest();
      var queryUrl = url + '?q=' + jQuery( '#invite-email-search' ).val();

      jQuery.ajax({
        type: 'get',
        dataType: 'json',
        url: queryUrl,
        complete: function (data) {
        },
        success: function (data) {
          if( data.success > 0 ){
             console.log( data.users );
      	    var list = data.users.map(function(i) { return i.username; });
             console.log( list );
            awesomplete.list = list;
          } else {
              alert( data.errorMessage );
          }
        },
        error: function () {
          //
        }
      });

    });
  }

}


function gbsSerialSearch() {

  if( jQuery( '#product-key-input' ).length > 0 ){
    var input = document.getElementById("product-key-input");
    var url = jQuery( '#product-key-input' ).attr('data-search-url');
    var awesomplete = new Awesomplete(input, {
      minChars: 1,
      maxItems: 5,
      autoFirst: true
    });
    awesomplete.list = [];

    window.addEventListener("awesomplete-selectcomplete", function(e){
      console.log( jQuery( '#product-key-input' ).val() );
    }, false);

    jQuery( '#product-key-input' ).on('keyup', function(e) {
      var code = (e.keyCode || e.which);
      var special = ( code === 37 || code === 38 || code === 39 || code === 40 || code === 27 || code === 13 );
      var ajax = new XMLHttpRequest();
      var queryUrl = url + '?q=' + jQuery( '#product-key-input' ).val();

      jQuery.ajax({
        type: 'get',
        dataType: 'json',
        url: queryUrl,
        complete: function (data) {
        },
        success: function (data) {
          if( data.success > 0 ){
            console.log( data.serials );
            var list = data.serials.map(function(i) { return i.serial_number; });
             console.log( list );
            awesomplete.list = list;
          } else {
              alert( data.errorMessage );
          }
        },
        error: function () {
          //
        }
      });

    });
  }

}


function gbsUpgradeAccountModal(){

  jQuery('.btn-upgrade-account').click(function (e) {
      e.preventDefault();
      
      var title   = jQuery( this ).attr('data-message-title');
      var content = jQuery( this ).attr('data-message-content');
      var defaultTitle = jQuery( '#modal-upgrade-account .default-title' ).html();
      var defaultContent = jQuery( '#modal-upgrade-account .default-content' ).html();

      if( !title ){
        title = defaultTitle;
      }
      if( !content ){
        content = defaultContent;
      }

      // upgrade-account-notice
      jQuery( '#modal-upgrade-account' ).addClass('active');
      jQuery( '#modal-upgrade-account .title' ).html( title );
      jQuery( '#modal-upgrade-account .gbs-content' ).html( content );
  });


}
