
/**
 * 
 * 
 */
jQuery(document).ready(function () {

  var beepsCallbacks = function( newState, data ){
    console.log( newState );

    if( newState == 'finished' ){
      console.log( 'Finished' );
    }
    if( newState == 'started-start-countdown' ){
      jQuery( '.gbs-fitness-test-graphics-both' ).slideUp();
    }
    if( newState == 'stage-started' ){
      console.log( 'New stage started' );
    }
    if( newState == 'reset' ){
      jQuery( '.gbs-fitness-test-graphics-both' ).slideDown();
    }
  }
  
  
  var isBeepsTimerScreen = false;
  jQuery( '.gbs-beeps-timer' ).each( function(){
    var ns = jQuery( this ).attr( 'id' );
    var defaultObj = new gbsBeepsTimer();
    defaultObj.setup( ns, globalBeepsTestStages, 5000, beepsCallbacks );
    isBeepsTimerScreen = true;
    return false;
  });

  if( isBeepsTimerScreen ){
    //hide forms and continue button when loaded
    //jQuery( '.gbs-fitness-test-form' ).hide();
    //jQuery( '.gbs-fitness-test-conitnue' ).hide();
    //
  }

});
