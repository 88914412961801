
!(function () {
  'use strict';
  
  var updateInProgress = false;
  var timeoutId;
  var timeoutRefreshId;
  var rowUpdating = [];//TODO
  
  jQuery(document).ready(function () {
    gbsListSelector();

    jQuery( '#workout-session-init-form-btn-submit' ).click( function(){
      jQuery( '#workout-session-init-form' ).submit();
    });

    jQuery( '#workout-session-edit-form-btn-submit' ).click( function(){
      jQuery( '#workout-session-edit-form' ).submit();
    });

    jQuery( '.remove-participant' ).click( function( e ){
      e.preventDefault();

      var rowId = jQuery( this ).attr( 'data-participant-row-id' );
      var url = jQuery( this ).attr( 'href' );
      var postData = {
        "participant_row_id": rowId, 
        '_token' : jQuery('#workout-session-edit-form input[name=_token]').val()
      };
      
      gbsWorkoutSessionAjax( url, postData, 'participant-edit-row-' + rowId, function(){
        document.location.reload();
      } );

      return false;
    });

    jQuery('.participant-row textarea, .participant-row input, .participant-row select').on('input propertychange change', function( e ) {
      var rowId = jQuery(this).parents('.participant-row').attr('data-participant-row-id');
      clearTimeout( timeoutId );
      if(updateInProgress){
        return;
      }
      timeoutId = setTimeout(function() {
        updateParticipantRow( rowId );
      }, 1800);
    });

    jQuery( '#workout-session-edit-form-btn-add' ).on("click", function () {
      jQuery( '#workout-session-add-user-modal' ).addClass('active');
    });

    var isAddUserButtonClicked = false;
    jQuery( '#workout-session-add-user-btn-submit' ).click( function () {
      if (!isAddUserButtonClicked){
        isAddUserButtonClicked = true;
        var fbns = 'workout-add-selected-exercises';
        var url = '';
        var postData = '';

        var postData = jQuery('#workout-session-add-user-form').serialize();
        var url = jQuery('#workout-session-add-user-form').attr('action');
        var ns  = 'workout-session-add-user';

        gbsWorkoutSessionAjax( url, postData, ns, function( data ){
          setTimeout( function(){
            jQuery( '#workout-session-add-user-modal' ).slideDown(function () {
                jQuery(this).removeClass('active');
            });
            window.location.reload();
          }, 1000 );
        } );
      }
    });


  });
  

  function updateParticipantRow( rowId ){
    var ns = 'participant-edit-row-' + rowId;
    var url = jQuery( '#participant-edit-row-' + rowId ).attr( 'data-update-url' );

    var postData = {
      "participant_row_id": rowId,
      "right_arm_pressure": jQuery( '#' + ns + '-right_arm_pressure' ).val(),
      "right_leg_pressure": jQuery( '#' + ns + '-right_leg_pressure' ).val(),
      "workout_id": jQuery( '#' + ns + '-workout_id' ).val(),
      "note": jQuery( '#' + ns + '-note' ).val(),
      '_token' : jQuery('#workout-session-edit-form input[name=_token]').val()
    };
    
    gbsWorkoutSessionAjax( url, postData, ns, function(){
      clearTimeout( timeoutRefreshId );
      timeoutRefreshId = setTimeout( function(){
        // document.location.reload();
      });
    } );
  }


  function gbsWorkoutSessionAjax( url, postData, fbns, successCallback ) {
    updateInProgress = true;

    jQuery( '.alert' ).hide();

    jQuery( '#' + fbns + '-error' ).hide();
    jQuery( '#' + fbns + '-success' ).hide();
    jQuery( '#' + fbns + '-loading' ).show();
    
    jQuery.ajax({
      data: postData,
      type: 'post',
      dataType: 'json',
      url: url,
      complete: function (data) {
        jQuery( '#' + fbns + '-loading' ).hide();
        updateInProgress = false;
      },
      success: function (data) {
        if( data.success > 0 ){
          jQuery( '#' + fbns + '-success' ).html( data.successMessage ).show();
          successCallback( data );
        } else {
          jQuery( '#' + fbns + '-error' ).html( data.errorMessage ).show( );
        }
      },
      error: function () {
        jQuery( '#' + fbns + '-error' ).html( jQuery( '#' + fbns + '-error' ).attr('data-general-error') ).show( );
      }
    });

  }


  var gbsSelectedUserIds = [];
  function gbsListSelector() {
    
    jQuery( '#workout-session-add-user-list' ).on("click", ".list-select-item input", function () {
    
      var id = jQuery( this ).attr( 'data-list-id' );
      var checked = jQuery( this ).prop( 'checked' );
      gbsSelectedUserIds = gbsListSelectorRemoveArrayItemIfFound( gbsSelectedUserIds, id );

      if( checked ){
        gbsSelectedUserIds[ gbsSelectedUserIds.length ] = id;
      }

      var joinedIds = gbsSelectedUserIds.join( ',' );
      jQuery( this ).parents( '.list-selector-wrapper' ).find( '.selected_ids' ).val( joinedIds );
    });
    
  }


  function gbsListSelectorRemoveArrayItemIfFound(arr, value) {
    for(var i = 0; i < arr.length; i++) {
      if(arr[i] === value) {
        arr.splice(i, 1);
        break;
      }
    }
    return arr;
  }

}());

